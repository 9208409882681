<template>
	<div class="pb">
		<div class="container-fluid">
			<div class="row">
				<div class="col-8">
					<div class="pb__inner d-flex flex-column">
						<h3 class="pb__desc">Do you want to integrate our cutting-edge <br> technology with your existing apps?</h3>
						<button class="pb__raa--button btn" @click="requestApi()">Request API Access <font-awesome-icon icon="fa-solid fa-arrow-right"/></button>
					</div>
				</div>
				<div class="col-4">
					<img src="@/assets/images/plugins/connect.svg" alt="Plugins" class="img-fluid">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},

	methods: {
		requestApi() {
			window.open('https://docs.google.com/forms/d/e/1FAIpQLSeOoSEioPwf7RkjfljSq3GJyt1dr_IMQg7FL4m8sOPyu80s7Q/viewform?pli=1', '_blank')
		}
	}
}
</script>

<style scoped>
.pb {
	width: 100%;
	background: linear-gradient(135deg, #28229D 0%, #A31A68 100%);
	border-radius: 8px;
	padding: 35px 50px;
}

.pb__desc {
	font-size: 30px;
	font-weight: 600;
}

.pb__inner {
	gap: 15px;
}

.pb__raa--button {
	background-color: #fff;
	font-weight: 900;
	font-size: 14px;
	color: #6D75F6;
	transition: background-color 0.25s;
	width: fit-content;
	padding: 10px 15px;
}

.pb__raa--button:hover {
	background-color: #ffffffe3;
}
</style>