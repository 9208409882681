<template>
	<div class="pl d-flex flex-column">
		<div v-for="(data, index) in listPlugins" :key="index">
			<p class="pl--title m-0">{{ data.title }}</p>
			<div class="pl__container--sl mt-2">
				<div class="pl__wrapper">
					<div v-for="(plug, i) in data.data" :key="i" class="plc card">
						<img :src="plug.img" alt="">
						<div class="card-body text-center">
							<p class="cb--title mb-1">{{ plug.title }}</p>
							<p class="cb--subtitle mb-3" :title="plug.subTitle">{{ plug.subTitle }}</p>
							<button class="cb__button-connect btn" disabled>Connect</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		listPlugins: {
			type: Array,
			default: () => {
				return []
			}
		}
	},
	data() {
		return {

		}
	},
}
</script>

<style scoped>
.pl {
	gap: 16px;
}

.pl--title {
	font-size: 14px;
	font-weight: 400;
}

.pl__container--sl {
	overflow-x: auto;
}


.pl__container--sl::-webkit-scrollbar-thumb {
	display: none;
}

.pl__wrapper {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: nowrap;
}

.plc {
	flex: 0 0 13rem;
	border-radius: 9px;
}

.cb--title {
	font-size: 14px;
	color: #fff;
	font-weight: 900;
}

.cb--subtitle {
	font-size: 10px;
	color: #fff;
	font-weight: 400;
	line-height: 10px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.cb__button-connect {
	background-color: #6D75F6;
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
	padding: 5px;
	width: 100%;
	transition: background-color 0.25s;
}

.cb__button-connect:not(:disabled):hover {
	background-color: #545EED;
}

.cb__button-connect:disabled {
	background-color: #2d2d2d;
 	color: #585859;
}
</style>