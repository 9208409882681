<template>
	<div class="plugins d-flex flex-column container-fluid">
		<h3 class="p__main-title">Plugins</h3>
		<div class="p__sub-mt">Connect with your favorite apps to boost creativity</div>
		<div class="pb__wrapper">
			<BannerPlugins />
		</div>
		<div class="pl__wrapper mt-2">
			<ListPlugins :list-plugins="listPluginsData"/>
		</div>
	</div>
</template>
<script>
import BannerPlugins from '@/components/plugins/banner'
import ListPlugins from '@/components/plugins/list'

export default {
	components: {
		BannerPlugins,
		ListPlugins
	},
	data() {
		return {
			listPluginsData: [
				{
					title : "Design & Publishing",
					data : [
						{
							img : require("@/assets/images/plugins/p_figma.png"),
							title : "Figma",
							subTitle: "Unlock creativity, collaboration, and productivity like never before."
						},
						{
							img : require("@/assets/images/plugins/p_unsplash.png"),
							title : "Unsplash",
							subTitle: "Elevate your project with high-quality, royalty-free images."
						},
						{
							img : require("@/assets/images/plugins/p_pexels.png"),
							title : "Pexels",
							subTitle: "Access stunning images to your project."
						},
						{
							img : require("@/assets/images/plugins/p_ig.png"),
							title : "Instagram",
							subTitle: "Seamlessly integrate Instagram content for engaging experiences."
						},
						{
							img : require("@/assets/images/plugins/p_tt.png"),
							title : "Tiktok",
							subTitle: "Unleash creativity to connect captivating content."
						},
					]
				},
				{
					title : "Productivity",
					data : [
						{
							img : require("@/assets/images/plugins/p_dropbox.png"),
							title : "Dropbox",
							subTitle: "Streamline collaboration for seamless workflow."
						},
						{
							img : require("@/assets/images/plugins/p_moodle.png"),
							title : "Moodle",
							subTitle: "Empower learning and collaboration for project."
						},
						{
							img : require("@/assets/images/plugins/p_gdrive.png"),
							title : "Google Drive",
							subTitle: "Collaborate and Amplify Project Efficiency."
						},
						{
							img : require("@/assets/images/plugins/p_gslides.png"),
							title : "Google Slides",
							subTitle: "Turbocharge project productivity to deliver stunning presentations."
						},
					]
				}
			]
		}
	},
}
</script>

<style scoped>
.plugins {
	color: #fff;
	text-align: left;
	gap: 16px;
}
.p__main-title {
	font-size: 24px;
	font-weight: 900;
}

.p__sub-mt {
	font-size: 16px;
	font-weight: 400;
}
</style>